import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { IRootState } from 'app/shared/reducers';
import { validateOTP, logout } from 'app/shared/reducers/authentication';
import HeaderPublic from 'app/shared/layout/header-public/header';
import OtpInput from 'react-otp-input';
import axios from 'axios';
import { BASE_URL } from 'app/utils/constant';
import { toast } from 'react-toastify';

export interface ILoginProps
    extends StateProps,
    DispatchProps,
    RouteComponentProps<{}> { }

export const OtpLogin = (props: ILoginProps) => {

    const [otp, setOtp] = useState('');
    const [otpError, setOtpError] = useState('');
    const [emailOTP, setEmailOTP] = useState('');
    const [employeeNo, setEmployeeNo] = useState('');

    useEffect(() => {
        handleRequestOTP(props?.account?.employeeNo)
    }, [])

    const handleRequestOTP = (employeeNo) => {
        setEmployeeNo(employeeNo)
        axios.post(`${BASE_URL.HOTSREGIS}api/backOffice/otp/v1/generate?userId=${employeeNo}`)
            .then((res) => {
                toast.success(res.data.message);
                setEmailOTP(res?.data?.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const handleOTP = async (value) => {
        if (value == '') {
            setOtpError('Required');
            return
        }
        setOtpError('');
        setOtp(value);
        if (value.length == 6) {
            axios.post(`${BASE_URL.HOTSREGIS}api/backOffice/otp/v1/validate?userId=${employeeNo}&otp=${value}`)
                .then((res) => {
                    const successCodeResp = res.data?.code;
                    if (successCodeResp == 211) {
                        setOtp("");
                        setOtpError("Please input the correct OTP");
                    } else {
                        props.validateOTP();
                    }
                })
                .catch((err) => {
                    // console.log("err", err.response?.data?.message);
                    const errCodeResp = err.response?.data?.code;
                    if (errCodeResp == 401 || errCodeResp == 500) {
                        toast.error(err.response?.data?.message, {
                            position: "top-left",
                            autoClose: 8000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        props.logout();
                    }
                });
        }
    }

    function maskEmailWithRegex(email) {
        return email.replace(/^(.{5})(.*)(@.*)$/, (_, firstThree, maskedPart, domain) => {
            const masked = "*".repeat(maskedPart.length);
            return `${firstThree}${masked}${domain}`;
        });
    }

    return (
        <>
            <HeaderPublic />
            <div style={{ height: '100%' }} className="container-lg">
                <div className='pt-5 py-5 d-flex justify-content-center align-items-center' style={{ height: "50vh" }}>
                    <div style={{ display: "flex", flexDirection: 'column' }}>
                        <div className={'mt-2 mb-2'} style={{ cursor: 'pointer' }} onClick={props.logout}>
                            <i className={'bx bx-arrow-back'} style={{ fontSize: "30px" }} />
                        </div>
                        <div className={'mt-2 mb-2'}>
                            <span style={{ fontSize: '20px', fontWeight: 'bold' }}>OTP</span>
                        </div>
                        <div className={'mt-2 mb-2'} style={{ textAlign: 'center' }}>
                            <span style={{ fontSize: '14px' }}>Masukkan kode verifikasi yang telah kami kirim melalui Email</span><br />
                            <span style={{ fontSize: '14px', fontWeight: 'bold' }}>{emailOTP}</span>
                        </div>
                        <div className={'mb-2 mt-2 d-flex justify-content-center align-items-center'}>
                            <OtpInput
                                value={otp}
                                onChange={(e) => {
                                    handleOTP(e)
                                }}
                                numInputs={6}
                                inputStyle={{
                                    fontSize: '18px',
                                    width: '40px',
                                    height: '40px',
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                    marginTop: '40px',
                                    borderTop: '0px',
                                    borderLeft: '0px',
                                    borderRight: '0px',
                                    outline: 'none',
                                    borderColor: '#000000',
                                    // border: "1px solid #000000"
                                }}
                                renderInput={(props) => <input {...props} />}
                            />
                        </div>
                        {otpError != '' ? (
                            <span className={'mt-2'} style={{ color: '#D8382E', textAlign: 'center' }}>
                                {otpError}
                            </span>
                        ) : null}
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = ({ authentication }: IRootState) => ({
    account: authentication.account
});

const mapDispatchToProps = {
    validateOTP,
    logout,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(OtpLogin);
